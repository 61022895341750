import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Figure, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  DelecteAccount,
  changeStatus,
  getUserProfile,
  verifyUser,
} from "../Redux/Actions/adminAction";
import logo from "../Assets/Images/placeholder 1 .png";
import { toast } from "react-toastify";
import moment from "moment";
import { CgLayoutGrid } from "react-icons/cg";

export default function WorkersProfile() {
  const [show, setshow] = useState(false);
  const [showdel, setshowdel] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  console.log(location, "loccc");
  const data = useSelector((state) => state.usermgmtData.userdetails);
  console.log(data, "userList");
  let BASE_URL = process.env.REACT_APP_FILE_BASE_URL;
  const navigate = useNavigate();

  const { id } = useParams();
  const [purpose, setPurpose] = useState(null);

  useEffect(() => {
    dispatch(getUserProfile({ userId: id }));
    if (location) {
      setPurpose(location?.state?.from);
    }
  }, [id, location]);

  const handledelclick = async () => {
    setshowdel(true);
  };

  const handleclick = async () => {
    setshow(true);
  };

  console.log(purpose);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Worker Profile</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link
                  to={
                    purpose == "userMangement"
                      ? "/UserManagement"
                      : "/WorkersVerification"
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              md={8}
              className="d-flex justify-content-end align-items-center p-0"
            >
              {/* <div className="cmn-btn">
                <Link to="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="19"
                    viewBox="0 0 20 19"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.001 13.541C9.58698 13.541 9.25098 13.205 9.25098 12.791V0.75C9.25098 0.336 9.58698 0 10.001 0C10.415 0 10.751 0.336 10.751 0.75V12.791C10.751 13.205 10.415 13.541 10.001 13.541Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.0008 13.541C9.80183 13.541 9.60983 13.462 9.46983 13.32L6.55383 10.393C6.26183 10.099 6.26283 9.62403 6.55583 9.33203C6.84983 9.04003 7.32383 9.04003 7.61583 9.33403L10.0008 11.729L12.3858 9.33403C12.6778 9.04003 13.1518 9.04003 13.4458 9.33203C13.7388 9.62403 13.7398 10.099 13.4478 10.393L10.5318 13.32C10.3918 13.462 10.1998 13.541 10.0008 13.541Z"
                      fill="white"
                    />
                    <mask
                      id="mask0_1581_17087"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="4"
                      width="20"
                      height="15"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0 4.73242H19.9998V18.4764H0V4.73242Z"
                        fill="white"
                      />
                    </mask>
                    <g mask="url(#mask0_1581_17087)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.575 18.4764H4.435C1.99 18.4764 0 16.4874 0 14.0414V9.15642C0 6.71642 1.985 4.73242 4.426 4.73242H5.367C5.781 4.73242 6.117 5.06842 6.117 5.48242C6.117 5.89642 5.781 6.23242 5.367 6.23242H4.426C2.812 6.23242 1.5 7.54342 1.5 9.15642V14.0414C1.5 15.6604 2.816 16.9764 4.435 16.9764H15.575C17.187 16.9764 18.5 15.6634 18.5 14.0514V9.16742C18.5 7.54842 17.183 6.23242 15.566 6.23242H14.634C14.22 6.23242 13.884 5.89642 13.884 5.48242C13.884 5.06842 14.22 4.73242 14.634 4.73242H15.566C18.011 4.73242 20 6.72242 20 9.16742V14.0514C20 16.4914 18.014 18.4764 15.575 18.4764Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                  Export
                </Link>
              </div> */}
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4">
        <Row className="pb-4">
          <Col lg={12}>
            <Row>
              <Col lg={8}>
                <div className="user-profile-main">
                  <Row>
                    <Col lg={12} xl={12} xxl={12}>
                      <div className="d-flex figure">
                        <span>
                          <img
                            alt="171x180"
                            src={
                              data?.data?.profile_image
                                ? `${BASE_URL}${data?.data?.profile_image}`
                                : logo
                            }
                          />
                        </span>
                        <Figure.Caption>
                          <div className="property-main mt-0">
                            <h2 className="property">Profile info</h2>
                            <div className="customer-form-new border-none">
                              <Row>
                                <Col lg={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Profile name</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="dummy"
                                      value={
                                        data?.data?.full_name
                                          ? data?.data?.full_name
                                          : "N/A"
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                      type="email"
                                      placeholder="dummy"
                                      value={data?.data?.email}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Phone number</Form.Label>
                                    <Form.Control
                                      type="test"
                                      placeholder="Phone"
                                      value={
                                        data?.data?.phone_number
                                          ? `${
                                              data?.data?.country_code || ""
                                            } ${data?.data?.phone_number}`
                                          : "N/A"
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Date of birth</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="dummy"
                                      value={
                                        data?.data?.dob
                                          ? moment(data?.data?.dob).format(
                                              "DD/MM/YYYY"
                                            )
                                          : "N/A"
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Gender</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="dummy"
                                      value={
                                        data?.data?.gender == 0
                                          ? "Male"
                                          : data?.data?.gender == 1
                                          ? "Female"
                                          : "Other"
                                      }
                                    />
                                  </Form.Group>
                                </Col>{" "}
                                <Col lg={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="dummy"
                                      value={
                                        data?.data?.address
                                          ? data?.data?.address
                                          : "N/A"
                                      }
                                    />
                                  </Form.Group>
                                </Col>{" "}
                                {/* <Col lg={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Location</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="dummy"
                                      value={data?.data?.landMark}
                                    />
                                  </Form.Group>
                                </Col>{" "} */}
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>About me </Form.Label>
                                    <Form.Control
                                      as="textarea"
                                      rows={3}
                                      placeholder="dummy"
                                      value={
                                        data?.data?.about_me
                                          ? data?.data?.about_me
                                          : "N/A"
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col
                                  lg={12}
                                  className="d-flex justify-content-end mt-3"
                                >
                                  {/* <button type="button" className="add-btn">
                                  Save
                                </button> */}
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Figure.Caption>
                      </div>
                    </Col>
                    {/* <Col lg={8} xl={9} xxl={10}>
                      <div className=" px-3 py-4 h-100"></div>
                    </Col> */}
                  </Row>
                </div>
                <Row>
                  <Col lg={12}>
                    <div className="user-profile-main px-3 mt-4 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">Work experience</h2>
                        <div className="customer-form-new border-none">
                          {data?.data?.work_experience?.length > 0 ? (
                            data?.data?.work_experience?.map((exp) => {
                              return (
                                <Row>
                                  <Col lg={6}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>Job title</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="dummy"
                                        value={exp?.job_title}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>Company </Form.Label>
                                      <Form.Control
                                        type="email"
                                        placeholder="dummy"
                                        value={exp?.company}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>Start date</Form.Label>
                                      <Form.Control
                                        type="test"
                                        placeholder="Phone"
                                        value={moment(exp?.start_date).format(
                                          "DD/MM/YYYY"
                                        )}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>End date</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="dummy"
                                        // value={
                                        //   data?.data?.address
                                        //     ? data?.data?.address
                                        //     : "N/A"
                                        // }
                                        value={moment(exp?.end_date).format(
                                          "DD/MM/YYYY"
                                        )}
                                      />
                                    </Form.Group>
                                  </Col>

                                  <Col lg={12}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>Description </Form.Label>
                                      <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder="dummy"
                                        value={exp?.description}
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>
                              );
                            })
                          ) : (
                            <p style={{ textAlign: "center" }}>
                              No data updated yet!
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Row>
                    <Col lg={12}>
                      <div className="user-profile-main px-3 mt-4  py-4 h-100">
                        <div className="property-main mt-0">
                          <h2 className="property">SPECIALIZATION</h2>
                          <div className="customer-form-new border-none">
                            <Row>
                              <Col lg={12}>
                                <Form.Group className="mb-3">
                                  <Form.Label>Area of expertise</Form.Label>
                                  {/* {Array.isArray(data?.data?.area_of_expertise) && data?.data?.area_of_expertise?.map(
                                    (expertise) => {
                                      return ( */}
                                  <Form.Control
                                    type="text"
                                    placeholder="dummy"
                                    value={data?.data?.specialization}
                                  />
                                  {/* );
                                    }
                                  )} */}
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={4}>
                <Row>
                  <Col lg={12}>
                    <div className="user-profile-main  px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">Profile Action</h2>
                        {purpose == "userMangement" ? (
                          <div className="profile-ac-btn">
                            {" "}
                            <button
                              onClick={
                                handleclick

                                // dispatch(
                                //   verifyUser({
                                //     userId: id,
                                //     type: 1,
                                //   })
                                // ).then((res) => {
                                //   if (res?.payload?.success) {
                                //     toast.success(res?.payload?.message);
                                //     navigate("/WorkersVerification");
                                //   } else {
                                //     toast.error(res?.payload?.message);
                                //   }
                                // });
                              }
                            >
                              Suspend
                            </button>
                            <button
                              onClick={
                                handledelclick
                                // dispatch(
                                //   verifyUser({
                                //     userId: id,
                                //     type: 0,
                                //   })
                                // ).then((res) => {
                                //   if (res?.payload?.success) {
                                //     toast.success(res?.payload?.message);
                                //     navigate("/WorkersVerification");
                                //   } else {
                                //     toast.error(res?.payload?.message);
                                //   }
                                // });
                              }
                            >
                              Delete
                            </button>
                          </div>
                        ) : (
                          <div className="profile-ac-btn">
                            <button
                              onClick={(e) => {
                                dispatch(
                                  verifyUser({
                                    userId: id,
                                    type: 1,
                                  })
                                ).then((res) => {
                                  if (res?.payload?.success) {
                                    toast.success(res?.payload?.message);
                                    navigate("/WorkersVerification");
                                  } else {
                                    toast.error(res?.payload?.message);
                                  }
                                });
                              }}
                            >
                              Verify
                            </button>
                            <button
                              onClick={(e) => {
                                dispatch(
                                  verifyUser({
                                    userId: id,
                                    type: 2,
                                  })
                                ).then((res) => {
                                  if (res?.payload?.success) {
                                    toast.success(res?.payload?.message);
                                    navigate("/WorkersVerification");
                                  } else {
                                    toast.error(res?.payload?.message);
                                  }
                                });
                              }}
                            >
                              Reject
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="user-profile-main px-3 mt-4  py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property mb-2">Documents</h2>
                        <div className="images-gallery">
                          <Row>
                            {data?.data?.upload_id || data?.data?.resume ? (
                              <>
                                {data?.data?.upload_id && (
                                  <Col lg={6}>
                                    <div className="image-box-item">
                                      <p>Identity Proof</p>
                                      <img
                                        src={
                                          data?.data?.upload_id
                                            ? `${BASE_URL}${data?.data?.upload_id}`
                                            : require("../Assets/Images/profile-img.png")
                                        }
                                        alt="proof"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          window.open(
                                            `${BASE_URL}${data?.data?.upload_id}`,
                                            "_blank"
                                          )
                                        }
                                      />
                                    </div>
                                  </Col>
                                )}
                                {data?.data?.resume && (
                                  <Col lg={6}>
                                    <div className="image-box-item">
                                      <p>Resume</p>
                                      {data?.data?.resume.endsWith(".pdf") ? (
                                        // Clickable link to open the PDF in a new tab
                                        <a
                                          href={`${BASE_URL}${data?.data?.resume}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{
                                            display: "block",
                                            textDecoration: "none",
                                            color: "#007bff",
                                            cursor: "pointer",
                                          }}
                                        >
                                          Click to view the PDF
                                        </a>
                                      ) : (
                                        // Display as an image and make it clickable
                                        <img
                                          src={
                                            data?.data?.resume
                                              ? `${BASE_URL}${data?.data?.resume}`
                                              : require("../Assets/Images/profile-img.png")
                                          }
                                          alt="resume"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            window.open(
                                              `${BASE_URL}${data?.data?.resume}`,
                                              "_blank"
                                            )
                                          }
                                        />
                                      )}
                                    </div>
                                  </Col>
                                )}
                              </>
                            ) : (
                              <div style={{ textAlign: "center" }}>
                                No data found
                              </div>
                            )}
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col>
                  {/* <Col lg={12} className="mt-4">
                    <div className="user-profile-main mt-4  px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">Bank details</h2>
                        <div className="customer-form-new border-none">
                          <Row>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Account holder name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="dummy"
                                  value={
                                    data?.data?.bank?.account_name
                                      ? data?.data?.bank?.account_name
                                      : "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Bank name</Form.Label>
                                <Form.Control
                                  type="email"
                                  placeholder="dummy"
                                  value={
                                    data?.data?.bank?.bank_name
                                      ? data?.data?.bank?.bank_name
                                      : "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Account number</Form.Label>
                                <Form.Control
                                  type="test"
                                  placeholder="Phone"
                                  value={
                                    data?.data?.bank?.account_number
                                      ? data?.data?.bank?.account_number
                                      : "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>IFSC code</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="dummy"
                                  value={
                                    data?.data?.bank?.ifsc_code
                                      ? data?.data?.bank?.ifsc_code
                                      : "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col> */}

                  <Col lg={12} className="mt-4">
                    <div className="user-profile-main px-3 mt-4  py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property mb-2">Photos</h2>
                        <div className="images-gallery">
                          <Row>
                            {data?.data?.photos?.length > 0 ? (
                              data.data.photos.map((profile, index) => (
                                <Col lg={6} key={index}>
                                  <div className="image-box-item">
                                    <a
                                      href={`${BASE_URL}${profile}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <img
                                        src={`${BASE_URL}${profile}`}
                                        alt={`phot${index}`}
                                        style={{
                                          width: "100%",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </a>
                                  </div>
                                </Col>
                              ))
                            ) : (
                              <p>No data found</p>
                            )}

                            {/* <Col lg={6}>
                              <div className="image-box-item">
                                <img
                                  src={require("../Assets/Images/profile-img.png")}
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="image-box-item">
                                <img
                                  src={require("../Assets/Images/profile-img.png")}
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="image-box-item">
                                <img
                                  src={require("../Assets/Images/profile-img.png")}
                                />
                              </div>
                            </Col> */}
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Modal show={show} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            Are you sure you want to suspend this Account?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button
            className="theme-btn"
            onClick={() => {
              dispatch(changeStatus({ userId: id, type: 0 })).then((res) => {
                console.log(res, "reesss");
                if (res?.payload?.success) {
                  toast.success(res?.payload?.message);
                  setshow(false);
                } else {
                  toast.error(res?.payload?.message);
                }
              });
            }}
          >
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showdel} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            Are you sure you want to Delete this Account?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button
            className="theme-btn"
            onClick={() => {
              dispatch(DelecteAccount({ userId: id })).then((res) => {
                console.log(res, "tewrwrwrewewer");
                if (res?.payload?.data?.success) {
                  toast.success(res?.payload?.data?.message);
                  setshow(false);
                  navigate("/userManagement");
                } else {
                  toast.error(res?.payload?.data?.message);
                }
              });
            }}
          >
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowdel(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
