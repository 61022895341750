import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ["N/A"],
  datasets: [
    {
      label: "# of Votes",
      data: [100],
      backgroundColor: ["#2C5F2D"],

      borderWidth: 1,
    },
  ],
};

export default function Orders() {
  return <Doughnut className="gender-chart" data={data} />;
}
