import { createSlice } from "@reduxjs/toolkit";
// import { userListing } from "../Actions/AdminActions";
import { toast } from "react-toastify";
import { getUserProfile, userListing, workerVerification } from "../Actions/adminAction";

export const userMgmtSlice = createSlice({
  name: "userMgmtSlice",
  initialState: {
    userList: "",
    userdetails:"",
    workerDetails:""
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userListing?.fulfilled, (state, action) => {
      if (action?.payload?.success) {
        state.userList = action.payload;
      } else {
        toast.error(action?.payload?.message);
      }
    });
    builder.addCase(getUserProfile?.fulfilled, (state, action) => {
      
      if (action?.payload?.success) {
        
        state.userdetails = action.payload;
      } else {
        toast.error(action?.payload?.message);
      }
    });
    builder.addCase(workerVerification?.fulfilled, (state, action) => {
      
      if (action?.payload?.success) {
        
        state.workerDetails = action.payload;
      } else {
        toast.error(action?.payload?.message);
      }
    });
  },
});

export default userMgmtSlice.reducer
