// Logout

import { toast } from "react-toastify";
import Adminapi from "../../services/AdminApi";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const LogOut = createAsyncThunk("LogOut", async () => {
  const { data } = await Adminapi.post("/auth/logout");
  console.log(data, "datadaty");

  if (data?.success) {
    toast.success(data?.message);
    window.location.href = "/";
    sessionStorage.clear();
  } else {
    toast.error(data?.message);
  }
});

//   change password

export const Changepassword = createAsyncThunk(
  "Changepassword",
  async (details) => {
    const data = await Adminapi.patch("/changePassword", details);
    console.log(data);
    return data;
  }
);

// Forgot Password

export const ForgotPass = createAsyncThunk(
  "ForgotPassword",
  async (details) => {
    const data = await Adminapi.post("/forgetpassword", details);
    return data;
  }
);

//  OtpVerify

export const OtpVerify = createAsyncThunk("OtpVerify", async (details) => {
  const data = await Adminapi.post("/verifyotp", details);
  return data;
});

// resetpassword

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (details) => {
    const data = await Adminapi.post("/resetPassword", details);
    return data;
  }
);

//   user listing

export const 
userListing = createAsyncThunk("userListing", async (details) => {
  // Destructure the parameters from details
  const { page, limit, search, type, status } = details;

  // Start with the base URL
  let url = "/userManagement";

  // Create an array to hold query parameters
  const queryParams = [];

  // Add query parameters if they exist
  if (page) queryParams.push(`page=${page}`);
  if (limit) queryParams.push(`limit=${limit}`);
  if (search) queryParams.push(`name=${encodeURIComponent(search)}`);
  if (type) queryParams.push(`type=${type}`);
  if (status) queryParams.push(`status=${status}`);

  // Combine query parameters into the URL
  if (queryParams.length > 0) {
    url += "?" + queryParams.join("&");
  }

  // Make the API request
  const { data } = await Adminapi.get(url);

  return data;
});

//  change profile status and suspend Account

export const changeStatus = createAsyncThunk(
  "changeStatus",
  async (details) => {
    const { data } = await Adminapi.patch(`/activateDeactivateUser`, details);

    return data;
  }
);

// Get user profile

export const getUserProfile = createAsyncThunk(
  "getUserProfile",
  async (details) => {
    const { data } = await Adminapi.get(
      `/userProfile?userId=${details.userId}`
    );
    return data;
  }
);

// Delete Account

export const DelecteAccount = createAsyncThunk(
  "DelecteAccount",
  async (details) => {
    const data = await Adminapi.delete(
      `/deleteAccount?userId=${details.userId}`
    );
    return data;
  }
);

// user list download

export const userListingDownload = createAsyncThunk(
  "userListingDownload",
  async (details) => {
    const { data } = await Adminapi.get(`/userManagement`);

    return data;
  }
);

// worker verification

export const workerVerification = createAsyncThunk(
  "workerVerification",
  async (details) => {
    const { data } = await Adminapi.get(
      `/verificationManagement?page=${details?.page}&limit=${details?.limit}&name=${details?.search}&type=${details?.type}`
    );

    return data;
  }
);
// worker verification download

export const workerVerificationdownload = createAsyncThunk(
  "workerVerificationdownload",
  async (details) => {
    const { data } = await Adminapi.get(`/verificationManagement?type=${details?.type}`);

    return data;
  }
);

// verify user

export const verifyUser = createAsyncThunk("verifyUser", async (details) => {
  const { data } = await Adminapi.put(`/verifyUser`, details);

  return data;
});

// dashboard

export const dashboard = createAsyncThunk(
  "dashboard",
  async (details) => {
    const { data } = await Adminapi.get(`/dashboard`);

    return data;
  }
);
