import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);



export default function Users(props) {

  const data = {
    labels: [`${props?.activeUserPerctage}% Active`, `${props?.deActiveUserPercentage}% Inactive`],
    datasets: [
      {
        // label: "# of Votes",
        data: [props?.activeUserPerctage, props?.deActiveUserPercentage],
        backgroundColor: ["#2C5F2D", "#D3D3D3"],
  
        borderWidth: 1,
      },
    ],
  };
  return <Doughnut className="gender-chart" data={data} />;
}
