import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Figure, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  DelecteAccount,
  changeStatus,
  getUserProfile,
} from "../Redux/Actions/adminAction";
import logo from "../Assets/Images/placeholder 1 .png";
import { toast } from "react-toastify";

export default function PaymentInfo() {
  const [show, setshow] = useState(false);
  const [showdel, setshowdel] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.usermgmtData.userdetails);
  console.log(data, "userList");
  let BASE_URL = process.env.REACT_APP_FILE_BASE_URL;

  const { id } = useParams();

  useEffect(() => {
    dispatch(getUserProfile({ userId: id }));
  }, [id]);

  const handledelclick = async () => {
    setshowdel(true);
  };

  const handleclick = async () => {
    setshow(true);
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Payment Info</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/Payments">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              md={8}
              className="d-flex justify-content-end align-items-center p-0"
            ></Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4">
        <Row className="pb-4">
          <Col lg={12}>
            <Row>
              <Col lg={6}>
                <div className="user-profile-main">
                  <Row>
                    <Col lg={12} xl={12} xxl={12}>
                      <div className="property-main mt-0">
                        <h2 className="property">Sender profile</h2>
                        <div className="customer-form-new border-none">
                          <Row>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Company name</Form.Label>
                                <Form.Control type="text" placeholder="dummy" />
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                  type="email"
                                  placeholder="dummy"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Phone number</Form.Label>
                                <Form.Control type="test" placeholder="Phone" />
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="property-main mt-0">
                        <h2 className="property">Payment amount</h2>
                        <div className="customer-form-new border-none">
                          <Row>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Amount</Form.Label>
                                <Form.Control type="text" placeholder="dummy" />
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="property-main mt-0">
                        <h2 className="property">Bank details</h2>
                        <div className="customer-form-new border-none">
                          <Row>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Account holder name</Form.Label>
                                <Form.Control type="text" placeholder="dummy" />
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Bank name</Form.Label>
                                <Form.Control type="text" placeholder="dummy" />
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Account number</Form.Label>
                                <Form.Control type="text" placeholder="dummy" />
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>IFSC code</Form.Label>
                                <Form.Control type="text" placeholder="dummy" />
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                    {/* <Col lg={8} xl={9} xxl={10}>
                      <div className=" px-3 py-4 h-100"></div>
                    </Col> */}
                  </Row>
                </div>
              </Col>
              <Col lg={6}>
                <Row>
                  <Col lg={12}>
                    <div className="user-profile-main  px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">Payment status</h2>

                        <div className="customer-form-new border-none">
                          <Row>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Control
                                  type="text"
                                  placeholder="Deliver Payment"
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                        <div className="profile-ac-btn">
                          <button>Payment Delivered</button>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col lg={12} className="mt-4">
                    <div className="user-profile-main mt-4  px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">receiver profile</h2>
                        <div className="customer-form-new border-none">
                          <Row>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Profile name</Form.Label>
                                <Form.Control type="text" placeholder="dummy" />
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                  type="email"
                                  placeholder="dummy"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Phone number</Form.Label>
                                <Form.Control type="test" placeholder="Phone" />
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="property-main mt-0">
                        <h2 className="property">Bank details</h2>
                        <div className="customer-form-new border-none">
                          <Row>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Account holder name</Form.Label>
                                <Form.Control type="text" placeholder="dummy" />
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Bank name</Form.Label>
                                <Form.Control
                                  type="email"
                                  placeholder="dummy"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Account number</Form.Label>
                                <Form.Control type="test" placeholder="Phone" />
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>IFSC code</Form.Label>
                                <Form.Control type="test" placeholder="Phone" />
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Modal show={show} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            Are you sure you want to suspend this Account
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button
            className="theme-btn"
            onClick={() => {
              dispatch(changeStatus({ userId: id, type: 0 })).then((res) => {
                if (res?.payload?.success) {
                  toast.success(res?.payload?.message);
                  setshow(false);
                } else {
                  toast.error(res?.payload?.message);
                }
              });
            }}
          >
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showdel} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            Are you sure you want to Delete this Account ?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button
            className="theme-btn"
            onClick={() => {
              dispatch(DelecteAccount({ userId: id, type: 0 })).then((res) => {
                if (res?.payload?.success) {
                  toast.success(res?.payload?.message);
                  setshow(false);
                } else {
                  toast.error(res?.payload?.message);
                }
              });
            }}
          >
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowdel(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
