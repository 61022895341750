import React from "react";
import Login from "./pages/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginOtp from "./pages/LoginOtp";
import ForgotPassword from "./pages/ForgotPassword";
import UserManagement from "./pages/UserManagement";
import Support from "./pages/Support";
import Dashboard from "./pages/Dashboard";
import Supportchat from "./pages/SupportChat";
// import UserActive from "./pages/UserActive";
import UserProfile from "./pages/UserProfile";
import WorkersVerification from "./pages/WorkersVerification";
import Notification from "./pages/Notification";
import AddNotification from "./pages/AddNotification";
import ChangePassword from "./pages/ChangePassword";
import ManagerProfile from "./pages/ManagerProfile";
import WorkersProfile from "./pages/WorkersProfile";
import ChannelInfo from "./pages/ChannelInfo";
import ForgotPasswordtwo from "./pages/ForgotPasswordtwo";
import WorkersUser from "./pages/WorkersUser";
import CompanyUser from "./pages/CompanyUser";
import CompanyVerification from "./pages/CompanyVerification";
import CompanyProfile from "./pages/CompanyProfile";
import CompanyVerifyProfile from "./pages/CompanyVerifyProfile";
import Payments from "./pages/Payments";
import PaymentInfo from "./pages/PaymentInfo";
import ProtectedRoutes from "./Components/Layout/ProtectedRoutes";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Otp" element={<LoginOtp />} />
          <Route path="/Forgot-Password" element={<ForgotPassword />} />
          <Route path="/ChangePasswordtwo" element={<ForgotPasswordtwo />} />

          <Route element={<ProtectedRoutes />}>
            <Route path="/Payments" element={<Payments />} />
            <Route path="/PaymentInfo" element={<PaymentInfo />} />
            <Route path="/WorkersProfile/:id" element={<WorkersProfile />} />
            <Route
              path="/CompanyVerifyProfile/:id"
              element={<CompanyVerifyProfile />}
            />
            <Route
              path="/CompanyVerification"
              element={<CompanyVerification />}
            />
            <Route path="/WorkersUser" element={<WorkersUser />} />

            <Route path="/UserManagement" element={<UserManagement />} />
            <Route path="/Support" element={<Support />} />
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="/CompanyProfile" element={<CompanyProfile />} />
            <Route path="/UserProfile/:id" element={<UserProfile />} />
            <Route path="/Supportchat" element={<Supportchat />} />
            {/* <Route path="/UserActive" element={<UserActive />} /> */}
            <Route
              path="/WorkersVerification"
              element={<WorkersVerification />}
            />
            <Route path="/Notification" element={<Notification />} />
            <Route path="/AddNotification" element={<AddNotification />} />
            <Route path="/ChangePassword" element={<ChangePassword />} />
            <Route path="/ManagerProfile" element={<ManagerProfile />} />
            <Route path="/ChannelInfo" element={<ChannelInfo />} />

            <Route path="/CompanyUser" element={<CompanyUser />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
